import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import styled from "styled-components"

export default class Subscribe extends React.Component {
  constructor() {
    super()
    this.state = { email: "", result: null }
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    alert(this.state.email)
    const result = await addToMailchimp(this.state.email)
    if (result.result === "error") {
      console.log(result)
      alert(result)
      //alert(`${this.state.email} is already subscribed.`)
    } else {
      //alert(`Thank you for subscribing ${this.state.name}!`)
      alert(result.result + " " + result.msg)
      console.log("Success")
    }
    this.setState({ result: result })
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  //handleNameChange = (event) => {
  //this.setState({ name: event.target.value })
  //}

  render() {
    return (
      <HeaderForm onSubmit={this.handleSubmit}>
        <HeaderInput
          onChange={this.handleEmailChange}
          placeholder="Your email"
          aria-label="Email input"
        ></HeaderInput>
        <HeaderButton aria-label="Submit">Early access</HeaderButton>
      </HeaderForm>
    )
  }
}

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`
const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
